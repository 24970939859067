/**
 *
 * @param {*} date
 * @returns
 */
export function ctimes(str) {
    if (str) {
        return str.split(' ')[0]
    } else {
        return ' '
    }
}