import Vue from 'vue'
import router from '@/router'

/**
 * 获取uuid
 */
export function getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
    })
}
/**
* 清除登录信息
*/
export function clearLoginInfo() {
    Vue.cookie.delete('cmsToken')
    // store.commit('resetStore')
    router.options.isAddDynamicMenuRoutes = false
}
/**
 * 获取svg图标(id)列表
 */
export function getIconList() {
    var res = []
    var list = document.querySelectorAll('svg symbol')
    for (var i = 0; i < list.length; i++) {
        res.push(list[i].id)
    }
    console.log(res,'icon ikun')
    return res
}