/**
 * 全站路由配置
 *
 * 建议:
 * 1. 代码中路由统一使用name属性跳转(不使用path属性)
 */
import Vue from 'vue'
import Router from 'vue-router'
import http from '@/utils/httpRequest'
import { isURL } from '@/utils/validate'
import { clearLoginInfo } from '@/utils'

Vue.use(Router)

// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
const _import = require('./import-' + process.env.NODE_ENV)

// 全局路由(无需嵌套上左右整体布局)
const globalRoutes = [
    { path: '/404', component: _import('common/404'), name: '404', meta: { title: '404未找到' } },
    { path: '/login', component: _import('common/login112'), name: 'login', meta: { title: '登录' } },
    { path: '/chat', component: _import('common/chat112'), name: 'chat', meta: { title: '群聊' } },
]

// 主入口路由(需嵌套上左右整体布局)
const mainRoutes = {
    path: '/',
    component: _import('main'),
    name: 'main',
    redirect: { name: 'home' },
    meta: { title: '主入口整体布局' },
    children: [
        // 通过meta对象设置路由展示方式
        // 1. isTab: 是否通过tab展示内容, true: 是, false: 否
        // 2. iframeUrl: 是否通过iframe嵌套展示内容, '以http[s]://开头': 是, '': 否
        // 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
        { path: '/home', component: _import('common/home112'), name: 'home', meta: { title: '首页', bigtitle: '个人工作台', noshow: true, shwodetails: true } },
        { path: '/search', component: _import('common/search112'), name: 'search', meta: { title: '搜索页', bigtitle: '搜索页', noshow: true, shwodetails: true } },
        { path: '/hengyu/common/msg/announcement', component: _import('tips/index'), name: 'announcement', meta: { title: '综合管理>通知公告', bigtitle: '综合管理' } },
        { path: '/hengyu/common/msg/createtip', component: _import('tips/createtip1121120'), name: 'createtip', meta: { title: '综合管理>创建通知', bigtitle: '综合管理', noshow: true } },
        { path: '/hengyu/common/msg/details', component: _import('tips/details'), name: 'tipsedit', meta: { title: '综合管理>修改通知', bigtitle: '综合管理', noshow: true } },
        { path: '/hengyu/common/msg/tipview', component: _import('tips/view'), name: 'tipview', meta: { title: '综合管理>通知公告', bigtitle: '综合管理', noshow: true } },
        { path: '/hengyu/common/employ/info', component: _import('users/index'), name: 'users', meta: { title: '综合管理>员工信息', bigtitle: '综合管理' } },
        { path: '/hengyu/common/employ/paper', component: _import('users/paper'), name: 'papers', meta: { title: '综合管理>员工证件', bigtitle: '综合管理' } },
        { path: '/hengyu/common/employ/paper1', component: _import('users/paperaa11aa22vbbs'), name: 'papersa', meta: { title: '综合管理>特种作业证', bigtitle: '综合管理' } },
        { path: '/hengyu/common/employ/paper2', component: _import('users/paperbb'), name: 'papersb', meta: { title: '综合管理>职称证', bigtitle: '综合管理' } },
        { path: '/hengyu/common/employ/paper3', component: _import('users/papercc66cc77bb88aa'), name: 'papersc', meta: { title: '综合管理>执业资格证', bigtitle: '综合管理' } },
        { path: '/hengyu/common/employ/paper4', component: _import('users/paperd'), name: 'papersd', meta: { title: '综合管理>岗位证', bigtitle: '综合管理' } },
        { path: '/hengyu/common/employ/paper5', component: _import('users/papere'), name: 'paperse', meta: { title: '综合管理>毕业证', bigtitle: '综合管理' } },
        { path: '/hengyu/common/employ/createuser', component: _import('users/createuser'), name: 'createuser', meta: { title: '综合管理>创建员工', bigtitle: '综合管理', noshow: true } },
        { path: '/hengyu/common/employ/view', component: _import('users/view'), name: 'userview', meta: { title: '综合管理>员工信息', bigtitle: '综合管理', noshow: true } },
        { path: '/hengyu/common/employ/details', component: _import('users/details'), name: 'userdetails', meta: { title: '综合管理>员工信息', bigtitle: '综合管理', noshow: true } },
        { path: '/hengyu/common/company/addressbook', component: _import('company/index112'), name: 'addressbook', meta: { title: '综合管理>公司通信录', bigtitle: '综合管理' } },
        { path: '/hengyu/common/company/paper', component: _import('company/paper112'), name: 'paper', meta: { title: '综合管理>公司证件管理', bigtitle: '综合管理' } },
        { path: '/hengyu/common/management/project', component: _import('management/project112'), name: 'project', meta: { title: '经营管理>项目管理', bigtitle: '经营管理', showselect: true, } },
        { path: '/hengyu/common/todotask/db2', component: _import('todotask/dbdbdbdbdbdbdbdbdbdbdbdbdbdbdbdbdbdbdbdb'), name: 'todotaskdb', meta: { title: '任务管理>我的待办', bigtitle: '经营管理',hback:true } },
        { path: '/hengyu/common/todotask/db', component: _import('todotask/newdb'), name: 'newtodotaskdb', meta: { title: '任务管理>我的待办', bigtitle: '经营管理', noshow: true } },
        { path: '/hengyu/common/todotask/details', component: _import('todotask/details112'), name: 'todotaskdetails', meta: { title: '任务管理>我的待办', bigtitle: '经营管理', noshow: true,  } },
        { path: '/hengyu/common/todotask/adetails', component: _import('todotask/details112'), name: 'todotaskadetails', meta: { title: '任务管理>全部任务', bigtitle: '经营管理', noshow: true,  } },
        { path: '/hengyu/common/todotask/all', component: _import('todotask/all11232453'), name: 'todotaskall', meta: { title: '任务管理>全部任务', bigtitle: '经营管理', } },
        { path: '/hengyu/common/todotask/report', component: _import('todotask/report112'), name: 'todotaskreport', meta: { title: '任务管理>任务报表', bigtitle: '经营管理',noshow: true, } },
        { path: '/hengyu/common/management/bid', component: _import('management/bid'), name: 'bid', meta: { title: '经营管理>投标管理', bigtitle: '经营管理' } },
        { path: '/hengyu/common/management/nprojectdetails', component: _import('management/projectdetails112'), name: 'projectdetails', meta: { title: '经营管理>项目概括', bigtitle: '经营管理', noshow: true, shwodetails: true, isPush: false } },
        { path: '/hengyu/common/management/biddetails', component: _import('management/bidDetails112'), name: 'bidDetails', meta: { title: '经营管理>投标任务详情', bigtitle: '经营管理', noshow: true, shwodetails: true } },
        { path: '/hengyu/common/management/bidview', component: _import('management/bidView'), name: 'bidView', meta: { title: '经营管理>投标任务详情', bigtitle: '经营管理', noshow: true, shwodetails: true } },
        { path: '/hengyu/common/management/createbid', component: _import('management/createbid2'), name: 'createBid', meta: { title: '经营管理>创建投标任务', bigtitle: '经营管理', noshow: true, } },
        { path: '/hengyu/common/management/projectview', component: _import('management/projectview112'), name: 'projectview', meta: { title: '经营管理>项目概括', bigtitle: '经营管理', noshow: true, shwodetails: true, isPush: false } },
        { path: '/hengyu/common/management/createproject', component: _import('management/createproject112'), name: 'createproject', meta: { title: '经营管理>创建项目', bigtitle: '经营管理', noshow: true, shwodetails: true } },
        { path: '/hengyu/common/management/payment', component: _import('management/fksqq112'), name: 'payment', meta: { title: '项目管理>付款申请单', bigtitle: '经营管理', noshow: true, } },
        { path: '/hengyu/common/management/testProject', component: _import('management/syxm112'), name: 'testProject', meta: { title: '项目管理>试验项目结算单', bigtitle: '经营管理', noshow: true, } },
        { path: '/hengyu/flowmanage/all/qj', component: _import('approval/all/leave'), name: 'allLeave', meta: { title: '我申请的>请假', bigtitle: '审批管理', } },
        { path: '/hengyu/common/approval/all/leaveDetails', component: _import('approval/all/leaveDetails'), name: 'leaveDetails', meta: { title: '我申请的>请假', bigtitle: '审批管理', noshow: true, } },
        { path: '/hengyu/flowmanage/all/rcbx', component: _import('approval/all/reimbursement'), name: 'allReimbursement', meta: { title: '我申请的>日常报销', bigtitle: '审批管理', } },
        { path: '/hengyu/common/approval/all/reDetails', component: _import('approval/all/reDetails'), name: 'reDetails', meta: { title: '我申请的>日常报销', bigtitle: '审批管理', noshow: true, } },
        { path: '/hengyu/flowmanage/all/cl', component: _import('approval/all/travel'), name: 'allTravel', meta: { title: '我申请的>差旅报销', bigtitle: '审批管理', } },
        { path: '/hengyu/common/approval/all/travelDetails', component: _import('approval/all/travelDetails'), name: 'travelDetails', meta: { title: '我申请的>差旅报销', bigtitle: '审批管理', noshow: true, } },
        { path: '/hengyu/flowmanage/all/jk', component: _import('approval/all/loan'), name: 'allLoan', meta: { title: '我申请的>借款申请', bigtitle: '审批管理', } },
        { path: '/hengyu/common/approval/all/loanDetails', component: _import('approval/all/loanDetails'), name: 'loanDetails', meta: { title: '我申请的>借款申请', bigtitle: '审批管理', noshow: true, } },
        { path: '/hengyu/common/approval/all/pDetails', component: _import('approval/all/pdetails'), name: 'pDetails', meta: { title: '我申请的>付款', bigtitle: '审批管理', noshow: true, } },
        { path: '/hengyu/flowmanage/all/fk', component: _import('approval/all/payment'), name: 'allPayment', meta: { title: '我申请的>付款', bigtitle: '审批管理', } },
        { path: '/hengyu/tools/cars', component: _import('tools/cars'), name: 'cars', meta: { title: '设备机具>车辆管理', bigtitle: '设备机具', } },
        { path: '/hengyu/tools/carDetails', component: _import('tools/carDetails112cardet223dea'), name: 'carDetails', meta: { title: '设备机具>车辆详情', bigtitle: '设备机具', noshow: true, shwodetails: true } },
        { path: '/hengyu/tools/trial', component: _import('tools/trial'), name: 'trial', meta: { title: '设备机具>试验设备管理', bigtitle: '设备机具', } },
        { path: '/hengyu/tools/trialDetails', component: _import('tools/trialDetails'), name: 'trialDetails', meta: { title: '设备机具>试验设备管理', bigtitle: '设备机具', noshow: true, shwodetails: true } },
        { path: '/hengyu/tools/safety', component: _import('tools/safety112'), name: 'safety', meta: { title: '设备机具>密钥证书管理', bigtitle: '设备机具', } },
        { path: '/hengyu/tools/safetyDetails', component: _import('tools/safetyDetails'), name: 'safetyDetails', meta: { title: '设备机具>安全工器具管理', bigtitle: '设备机具', noshow: true, shwodetails: true } },
        { path: '/hengyu/tools/certificate', component: _import('tools/certificate'), name: 'certificate', meta: { title: '设备机具>安全工器具管理', bigtitle: '设备机具', } },
        { path: '/hengyu/tools/certificateDetails', component: _import('tools/certificateDetails'), name: 'certificateDetails', meta: { title: '设备机具>企业证书管理', bigtitle: '设备机具', noshow: true, shwodetails: true } },
        { path: '/hengyu/flowmanage/db/qj', component: _import('approval/all/leave'), name: 'dbLeave', meta: { title: '待审批>请假', bigtitle: '审批管理', } },
        { path: '/hengyu/flowmanage/db/cl', component: _import('approval/all/travel'), name: 'dbTravel', meta: { title: '待审批>差旅报销', bigtitle: '审批管理', } },
        { path: '/hengyu/flowmanage/db/rcbx', component: _import('approval/all/reimbursement'), name: 'dbRe', meta: { title: '待审批>日常报销', bigtitle: '审批管理', } },
        { path: '/hengyu/flowmanage/db/jk', component: _import('approval/all/loan'), name: 'dbLoan', meta: { title: '待审批>借款申请', bigtitle: '审批管理', } },
        { path: '/hengyu/flowmanage/db/fk', component: _import('approval/all/payment'), name: 'dbPayment', meta: { title: '待审批>付款', bigtitle: '审批管理', } },
        { path: '/hengyu/flowmanage/yb/qj', component: _import('approval/all/leave'), name: 'ybLeave', meta: { title: '已审批>请假', bigtitle: '审批管理', } },
        { path: '/hengyu/flowmanage/yb/cl', component: _import('approval/all/travel'), name: 'ybTravel', meta: { title: '已审批>差旅报销', bigtitle: '审批管理', } },
        { path: '/hengyu/flowmanage/yb/rcbx', component: _import('approval/all/reimbursement'), name: 'ybRe', meta: { title: '已审批>日常报销', bigtitle: '审批管理', } },
        { path: '/hengyu/flowmanage/yb/jk', component: _import('approval/all/loan'), name: 'ybLoan', meta: { title: '已审批>借款申请', bigtitle: '审批管理', } },
        { path: '/hengyu/flowmanage/yb/fk', component: _import('approval/all/payment'), name: 'ybPayment', meta: { title: '已审批>付款', bigtitle: '审批管理', } },
        { path: '/sys/user', component: _import('sys/user112'), name: 'user', meta: { title: '系统设置>用户管理', bigtitle: '系統设置', } },
        { path: '/sys/role', component: _import('sys/role112'), name: 'role', meta: { title: '系统设置>角色管理', bigtitle: '系統设置', } },
        { path: '/sys/dept', component: _import('sys/dept12223'), name: 'dept', meta: { title: '系统设置>部门管理', bigtitle: '系統设置', } },
        { path: '/sys/menu', component: _import('sys/menu11'), name: 'menu', meta: { title: '系统设置>菜单管理', bigtitle: '系統设置', } },
        { path: '/sys/dict-type', component: _import('sys/dict-type'), name: 'dict-type', meta: { title: '系统设置>主数据管理', bigtitle: '系統设置', } },
        { path: '/sys/task', component: _import('sys/task'), name: 'systask', meta: { title: '系统设置>任务管理', bigtitle: '系統设置', } },
        { path: '/sys/approve', component: _import('sys/appwadazzeeee12'), name: 'sysapprove', meta: { title: '系统设置>流程审批管理', bigtitle: '系統设置', } },
        { path: '/sys/approve/details', component: _import('sys/abcdehfsuahfkjhghjhgjhvngvngcbfcgrfdxgfesdtruujjmmkiolpihggbnbnvczxddertygffcvhhjjuhygfsdsweerttgvvbnmmjklopiutygbgsdasdwrtfgaf'), name: 'afksq', meta: { title: '系统设置>流程审批管理', bigtitle: '系統设置', noshow: true, shwodetails: true } },
        { path: '/sys/setting', component: _import('sys/setting112'), name: 'setting', meta: { title: '个人设置>个人设置', bigtitle: '个人设置', noshow: true, shwodetails: true } },
        { path: '/hengyu/file/all/common', component: _import('files/index112'), name: 'files', meta: { title: '文档管理>所有文档', bigtitle: '文档管理' } },
        { path: '/hengyu/file/all/my', component: _import('files/myfile112'), name: 'myfiles', meta: { title: '文档管理>我上传的', bigtitle: '文档管理' } },
        // { path: '/theme', component: _import('common/theme'), name: 'theme', meta: { title: '主题' } },
        // { path: '/demo-echarts', component: _import('demo/echarts'), name: 'demo-echarts', meta: { title: 'demo-echarts', isTab: true } },
        // { path: '/demo-ueditor', component: _import('demo/ueditor'), name: 'demo-ueditor', meta: { title: 'demo-ueditor', isTab: true } },
        // { path: '/user/user-details', component: _import('modules/user/user-details'), name: 'user-details', meta: { title: '用户详情', isTab: true } },
        // { path: '/user/ordinaryedit', component: _import('modules/user/ordinaryedit'), name: 'user-ordinaryedit', meta: { title: '普通用户管理', isTab: true } },
        // { path: '/user/merchantedit', component: _import('modules/user/merchantedit'), name: 'user-merchantedit', meta: { title: '入驻企业管理', isTab: true } },
        // { path: '/workorder/details', component: _import('modules/workorder/details'), name: 'workorder-details', meta: { title: '企业服务工单详情', isTab: true } },
        // { path: '/ant/parttimejob-add', component: _import('modules/ant/parttimejob-add'), name: 'parttimejob-add', meta: { title: '项目信息', isTab: true } },
        // { path: '/ant/designerManagementBaseMess/:id', component: _import('modules/designer/designerManagement_baseMess'), name: 'designerManagementBaseMess', meta: { title: '基本资料', isTab: true } }
    ],
    beforeEnter(to, from, next) {
        let token = Vue.cookie.get('cmsToken')
        if (!token || !/\S/.test(token)) {
            clearLoginInfo()
            next({ name: 'login' })
        }
        next()
    }
}

const router = new Router({
    mode: 'hash',
    scrollBehavior: () => ({ y: 0 }),
    isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
    routes: globalRoutes.concat(mainRoutes)
})

router.beforeEach((to, from, next) => {
    // 添加动态(菜单)路由
    // 1. 已经添加 or 全局路由, 直接访问
    // 2. 获取菜单列表, 添加并保存本地存储
    if (router.options.isAddDynamicMenuRoutes || fnCurrentRouteType(to, globalRoutes) === 'global') {
        next()
    } else {
        http({
            url: http.adornUrl('/oa/sys/menu/nav'),
            method: 'get',
            params: http.adornParams()
        }).then(({ data }) => {
            if (data && data.code === 0) {
                // 内网版cms暂时菜单
                // let newMenuList = []
                // data.menuList.forEach(t => {
                //   if (t.name === '轮播图管理' || t.name === '新闻管理' || t.name === '作品超市' || t.name === '主数据') {
                //     newMenuList.push(t)
                //   }
                // })
                // console.log(newMenuList)
                // let arr = data.menuList.filter(item => item.name != "入驻商户")
                // arr.forEach(t => {
                //   if (t.name = "用户管理") {
                //     t.list.forEach((t2, i) => {
                //       if (t2.name == "会员管理" || t2.name == "用户列表") {
                //         t.list.splice(i, 1)
                //       }
                //     })
                //   }
                // })
                // console.log(arr, '获取到菜单')
                fnAddDynamicMenuRoutes(data.data)
                // fnAddDynamicMenuRoutes(newMenuList)
                router.options.isAddDynamicMenuRoutes = true
                // sessionStorage.setItem('menuList', JSON.stringify(data.menuList || '[]'))
                // sessionStorage.setItem('permissions', JSON.stringify(data.permissions || '[]'))
                sessionStorage.setItem('menuList', JSON.stringify(data.data || '[]'))
                sessionStorage.setItem('permissions', JSON.stringify(data.data || '[]'))
                next({ ...to, replace: true })
            } else {
                sessionStorage.setItem('menuList', '[]')
                sessionStorage.setItem('permissions', '[]')
                next()
            }
        }).catch((e) => {
            console.log(`%c${e} 请求菜单列表和权限失败，跳转至登录页！！`, 'color:blue')
            router.push({ name: 'login' })
        })
    }
})

/**
 * 判断当前路由类型, global: 全局路由, main: 主入口路由
 * @param {*} route 当前路由
 */
function fnCurrentRouteType(route, globalRoutes = []) {
    var temp = []
    for (var i = 0; i < globalRoutes.length; i++) {
        if (route.path === globalRoutes[i].path) {
            return 'global'
        } else if (globalRoutes[i].children && globalRoutes[i].children.length >= 1) {
            temp = temp.concat(globalRoutes[i].children)
        }
    }
    return temp.length >= 1 ? fnCurrentRouteType(route, temp) : 'main'
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes(menuList = [], routes = []) {
    var temp = []
    for (var i = 0; i < menuList.length; i++) {
        if (menuList[i].list && menuList[i].list.length >= 1) {
            temp = temp.concat(menuList[i].list)
        } else if (menuList[i].url && /\S/.test(menuList[i].url)) {
            menuList[i].url = menuList[i].url.replace(/^\//, '')
            var route = {
                path: menuList[i].url.replace('/', '-'),
                component: null,
                name: menuList[i].url.replace('/', '-'),
                meta: {
                    menuId: menuList[i].menuId,
                    title: menuList[i].name,
                    isDynamic: true,
                    isTab: true,
                    iframeUrl: ''
                }
            }
            // url以http[s]://开头, 通过iframe展示
            if (isURL(menuList[i].url)) {
                route['path'] = `i-${menuList[i].menuId}`
                route['name'] = `i-${menuList[i].menuId}`
                route['meta']['iframeUrl'] = menuList[i].url
            } else {
                try {
                    route['component'] = _import(`modules/${menuList[i].url}`) || null
                } catch (e) { }
            }
            routes.push(route)
        }
    }
    if (temp.length >= 1) {
        fnAddDynamicMenuRoutes(temp, routes)
    } else {
        mainRoutes.name = 'main-dynamic'
        mainRoutes.children = routes
        router.addRoutes([
            mainRoutes,
            { path: '*', redirect: { name: '404' } }
        ])
        sessionStorage.setItem('dynamicMenuRoutes', JSON.stringify(mainRoutes.children || '[]'))
        // console.log('\n')
        // console.log('%c!<-------------------- 动态(菜单)路由 s -------------------->', 'color:blue')
        // console.log(mainRoutes.children)
        // console.log('%c!<-------------------- 动态(菜单)路由 e -------------------->', 'color:blue')
    }
}

export default router
