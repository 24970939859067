<template>
  <!-- <div id="app">
    <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div> -->
  <transition name="fade">
    <router-view v-wechat-title="newtitle"></router-view>
  </transition>
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  // components: {
  //   HelloWorld
  // }
  computed:{
    newtitle(){
      return '衡誉电气>' + (this.$route.meta.bigtitle=='审批管理'?this.$route.meta.bigtitle+'>'+this.$route.meta.title:this.$route.meta.title)
    }
  }
};
</script>

<style>
#app {
  user-select: none;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
<style lang="scss">
</style>

