import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import httpRequest from '@/utils/httpRequest'
import '@/element-ui'
import { ctimes } from '@/utils/filter'
import '@/icons'
import '@/element-ui-theme'
import '@/assets/scss/index.scss'                   // api: https://github.com/vuejs/vuex
import VueCookie from 'vue-cookie'
import elDragDialog from '@/diaLog'
import store from '@/store'
import '@/utils/rem'
import cloneDeep from 'lodash/cloneDeep'
import Plugin from 'v-fit-columns';
import '@/utils/directives.js'
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import Print from 'vue-print-nb'
import VueWechatTitle from 'vue-wechat-title'
Vue.config.productionTip = false
// const baseUrl = (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/proxyApi/' : window.SITE_CONFIG.baseUrl)
Vue.filter("ctimes", ctimes)
// new Vue({
//   render: h => h(App),
// }).$mount('#app')
Vue.directive('el-drag-dialog', elDragDialog);//添加标签事件绑定 可以放大移动弹窗
//弹窗默认点击遮罩改为不关闭 为了防止和拖拽冲突 ，这句需要放在use ElementUI之前（也可以不加这句，自己测试区别）
// ElementUI.Dialog.props.closeOnClickModal.default = false;
// Vue.use(ElementUI);
Vue.use(Viewer)
Vue.use(Plugin);
Vue.use(VueWechatTitle)
Vue.use(Print)

Vue.use(VueCookie)
// 挂载全局
Vue.prototype.$http = httpRequest // ajax请求方法
// new Vue({
//   el: '#app',
//   router,
//   template: '<App/>',
//   components: { App }
// })
// 保存整站vuex本地储存初始状态
window.SITE_CONFIG = {}
window.SITE_CONFIG['storeState'] = cloneDeep(store.state)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")

